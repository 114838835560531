import {
  ref,
  onUnmounted,
  watch,
  Ref,
} from 'vue'
import { message } from 'ant-design-vue'
import {
  DRC_METHOD,
  DroneControlProtocol,
} from '/@/types/drc'
import {
  useMqtt,
  DeviceTopicInfo
} from './use-mqtt'
import { useMyStore } from '/@/store'
import { v4 as uuidv4 } from 'uuid'
let myInterval: any

export enum KeyCode {
  KEY_W = 'KeyW',
  KEY_A = 'KeyA',
  KEY_S = 'KeyS',
  KEY_D = 'KeyD',
  KEY_Q = 'KeyQ',
  KEY_E = 'KeyE',
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
}

export function useManualControl(deviceTopicInfo: DeviceTopicInfo, isCurrentFlightController: Ref<boolean>, stores?: any) {
  const activeCodeKey = ref(null) as Ref<KeyCode | null>
  const mqttHooks = useMqtt(deviceTopicInfo)
  let seq = 0
  function handlePublish(params: DroneControlProtocol) {
    const body = {
      method: DRC_METHOD.DRONE_CONTROL,
      data: params,
    }
    handleClearInterval()
    myInterval = setInterval(() => {
      // window.console.log('keyCode>>>>', activeCodeKey.value, body)
      // body.data.seq = new Date().getTime()
      body.data.seq = seq++
      seq++
      // console.log('mqtt发出的指令1：', deviceTopicInfo.pubTopic)
      // console.log('mqtt发出的指令2：', body)

      mqttHooks?.publishMqtt(deviceTopicInfo.pubTopic, body, { qos: 0 })
    }, 50)
  }

  function handleKeyup(keyCode: KeyCode) {
    if (!deviceTopicInfo.pubTopic) {
      message.error('请确保已经建立DRC链路')
      return
    }
    const SPEED = 5 //  check
    const HEIGHT = 5 //  check
    const W_SPEED = 20 // 机头角速度
    switch (keyCode) {
      case 'KeyA':
        if (activeCodeKey.value === keyCode) return
        handlePublish({ y: -SPEED })
        activeCodeKey.value = keyCode
        break
      case 'KeyW':
        if (activeCodeKey.value === keyCode) return
        handlePublish({ x: SPEED })
        activeCodeKey.value = keyCode
        break
      case 'KeyS':
        if (activeCodeKey.value === keyCode) return
        handlePublish({ x: -SPEED })
        activeCodeKey.value = keyCode
        break
      case 'KeyD':
        if (activeCodeKey.value === keyCode) return
        handlePublish({ y: SPEED })
        activeCodeKey.value = keyCode
        break
      case 'ArrowUp':
        if (activeCodeKey.value === keyCode) return
        handlePublish({ h: HEIGHT })
        activeCodeKey.value = keyCode
        break
      case 'ArrowDown':
        if (activeCodeKey.value === keyCode) return
        handlePublish({ h: -HEIGHT })
        activeCodeKey.value = keyCode
        break
      case 'KeyQ':
        if (activeCodeKey.value === keyCode) return
        handlePublish({ w: -W_SPEED })
        activeCodeKey.value = keyCode
        break
      case 'KeyE':
        if (activeCodeKey.value === keyCode) return
        handlePublish({ w: W_SPEED })
        activeCodeKey.value = keyCode
        break
      default:
        break
    }
  }

  function handleClearInterval() {
    clearInterval(myInterval)
    myInterval = undefined
  }

  function resetControlState() {
    console.log('抬起指令')

    activeCodeKey.value = null
    seq = 0
    handleClearInterval()
  }

  function onKeyup() {
    resetControlState()
  }

  function onKeydown(e: KeyboardEvent) {
    handleKeyup(e.code as KeyCode)
  }

  function startKeyboardManualControl() {
    window.addEventListener('keydown', onKeydown)
    window.addEventListener('keyup', onKeyup)
  }

  function closeKeyboardManualControl() {
    resetControlState()
    window.removeEventListener('keydown', onKeydown)
    window.removeEventListener('keyup', onKeyup)
  }

  watch(() => isCurrentFlightController.value, (val) => {
    if (val && deviceTopicInfo.pubTopic) {
      startKeyboardManualControl()
    } else {
      closeKeyboardManualControl()
    }
  }, { immediate: true })

  onUnmounted(() => {
    closeKeyboardManualControl()
  })

  function handleEmergencyStop() {
    console.log('停止链路', deviceTopicInfo)

    if (!deviceTopicInfo.pubTopic) {
      message.error('请确保已经建立DRC链路')
      return
    }
    const body = {
      method: DRC_METHOD.DRONE_EMERGENCY_STOP,
      data: {}
    }
    resetControlState()
    window.console.log('handleEmergencyStop>>>>', deviceTopicInfo.pubTopic, body)
    mqttHooks?.publishMqtt(deviceTopicInfo.pubTopic, body, { qos: 1 })
  }
  // 航线暂停
  function handleEmergencySuspend() {
    console.log(stores.state.deviceState.currentSn, '暂停')
    console.log(stores.state.deviceState.dockInfo, '暂停')
    const keys = Object.keys(stores.state.deviceState.dockInfo)
    console.log(keys[0])

    const uuid1 = uuidv4()
    const uuid2 = uuidv4()

    const body = {
      method: DRC_METHOD.FLIGHTTASK_PAUSE,
      data: {},
      bid: uuid1,
      tid: uuid2,
      timestamp: new Date().getTime()
    }
    resetControlState()
    mqttHooks?.publishMqtt(`thing/product/${keys[0]}/services`, body, { qos: 1 })
  }
  // 通过mqtt使航线任务恢复
  function handleEmergencyGoon() {
    console.log(stores.state.deviceState.currentSn, '恢复')
    const keys = Object.keys(stores.state.deviceState.dockInfo)
    console.log(keys[0])

    const uuid1 = uuidv4()
    const uuid2 = uuidv4()
    const body = {
      method: DRC_METHOD.FLIGHTTASK_RECOVERY,
      data: {},
      bid: uuid1,
      tid: uuid2,
      timestamp: new Date().getTime()
    }
    resetControlState()
    mqttHooks?.publishMqtt(`thing/product/${keys[0]}/services`, body, { qos: 1 })
  }
  // 航线返航
  function handleEmergencyReturn() {
    console.log(stores.state.deviceState.currentSn, '返航')
    console.log(stores.state.deviceState.dockInfo, '返航123')

    const keys = Object.keys(stores.state.deviceState.dockInfo)
    console.log(keys[0])

    const uuid1 = uuidv4()
    const uuid2 = uuidv4()
    const body = {
      method: DRC_METHOD.RETURN_HOME,
      data: {},
      bid: uuid1,
      tid: uuid2,
      timestamp: new Date().getTime()
    }
    resetControlState()
    mqttHooks?.publishMqtt(`thing/product/${keys[0]}/services`, body, { qos: 1 })
  }

  return {
    activeCodeKey,
    handleKeyup,
    handleEmergencyStop,
    resetControlState,
    handleEmergencySuspend,
    handleEmergencyGoon,
    handleEmergencyReturn
  }
}
