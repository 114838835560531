<template>
  <div class="a">
    <div class="project-app-wrapper">
      <!-- <div v-if="root.$route.name === 'newdevices'" class="wrapper-header">
        设备管理
      </div> -->
      <a-collapse>
        <a-collapse-panel :key="EDeviceTypeName.Aircraft" header="机场">
          <div v-if="onlineDocks.data.length === 0" style="height: 150px; color: white;">
            <a-empty :image="noData" :image-style="{ height: '60px' }" />
          </div>
          <div v-else class="panel">
            <div class="panel-item" v-for="dock in onlineDocks.data" :key="dock.sn">
              <div class="item-name">
                <div class="item-name-icon"></div>
                <a-tooltip :title="`${dock.gateway.callsign} - ${dock.callsign ?? 'No Drone'}`">
                  <div class="item-name-text">{{ dock.gateway.callsign }} - {{ dock.callsign ?? 'No Drone' }}</div>
                </a-tooltip>
              </div>
              <div class="item-body">
                <div>
                  <div class="item-body-state">
                    <img :src="state2" alt="">

                    <div class="item-body-state-text">机场状态</div>
                    <div class="item-body-state-line"></div>
                    <div class="item-body-state-right"> {{ EDockModeCodeTranslate(dockInfo[dock.gateway.sn] ?
                      EDockModeCode[dockInfo[dock.gateway.sn].basic_osd?.mode_code] :
                      EDockModeCode[EDockModeCode.Disconnected]) }}</div>
                  </div>
                  <div class="item-body-state">
                    <img :src="state1" alt="">

                    <div class="item-body-state-text">无人机状态</div>
                    <div class="item-body-state-line"></div>
                    <div class="item-body-state-right"> {{ EModeCodeTranslate(deviceInfo[dock.sn] ?
                      EModeCode[deviceInfo[dock.sn].mode_code] : EModeCode[EModeCode.Disconnected]) }}
                    </div>
                  </div>
                </div>
                <div class="item-right" @click="back(dock)">

                    返航

                </div>

              </div>
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
      <a-collapse>
        <a-collapse-panel :key="EDeviceTypeName.Aircraft" header="设备" >
          <div v-if="onlineDevices.data.length === 0" style="height: 150px; color: #000;">
            <a-empty :image="noData" :image-style="{ height: '60px' }" />
          </div>
          <div v-else class="panel">
            <div class="panel-item" v-for="device in onlineDevices.data" :key="device.sn">
              <div class="item-name">
                <div class="item-name-icon"></div>
                <a-tooltip :title="`${device.gateway.callsign} - ${device.callsign ?? 'No Drone'}`">
                  <div class="item-name-text">{{ device.gateway.callsign }} - {{ device.callsign ?? 'No Drone' }}</div>
                </a-tooltip>
              </div>
              <div class="item-body">
                <div class="item-body-state">
                  <img :src="state1" alt="">
                  <div class="item-body-state-text">无人机状态</div>
                  <div class="item-body-state-line"></div>
                  <div class="item-body-state-right"> {{ EModeCodeTranslate(deviceInfo[device.sn] ?
                    EModeCode[deviceInfo[device.sn].mode_code] : EModeCode[EModeCode.Disconnected]) }} </div>
                </div>
                <div class="item-body-state">
                  <img :src="state2" alt="">
                  <!-- <div class="item-body-state-text">无人机运行状态</div>
                  <div class="item-body-state-line"></div> -->
                  <div class="item-body-state-right">{{ device.gateway.model }} - {{ device.gateway.callsign }}</div>
                </div>
              </div>
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
    <div class="r">

      <div class="all">
        <!-- <div class="all-header">
      <div class="all-header-icon"></div>
      <div class="all-header-text">设备管理</div>
    </div> -->
        <div class="all-body">
          <a-menu v-model:selectedKeys="current" mode="horizontal" @select="select">
            <a-menu-item :key="EDeviceTypeName.Aircraft" class="ml20">
              无人机
            </a-menu-item>
            <a-menu-item :key="EDeviceTypeName.Dock">
              机场
            </a-menu-item>
          </a-menu>
          <div class="device-table-wrap table flex-display flex-column">
            <a-table :columns="columns" :data-source="data.device" :pagination="false" @change="refreshData"
              row-key="device_sn" :expandedRowKeys="expandRows" :row-selection="rowSelection"
              :rowClassName="rowClassName" :scroll="{ x: 1400, y: 600 }" :expandIcon="expandIcon" :loading="loading">
              <template v-for="col in ['nickname']" #[col]="{ text, record }" :key="col">
                <div>
                  <a-input v-if="editableData[record.device_sn]" v-model:value="editableData[record.device_sn][col]"
                    style="margin: -5px 0" />
                  <template v-else>

                    <a-tooltip :title="text">

                      <span class="mr5" v-if="record.status"
                        style="display: inline-block; width: 12px; height: 12px; border-radius: 50%; background-color: green;" />
                      <span class="mr5" v-else
                        style="display: inline-block; width: 12px; height: 12px; border-radius: 50%; background-color: red;" />
                      {{ text }}
                      <!-- {{ record.status }} -->
                    </a-tooltip>
                  </template>
                </div>
              </template>
              <template v-for="col in ['sn', 'workspace']" #[col]="{ text }" :key="col">
                <a-tooltip :title="text">
                  <span>{{ text }}</span>
                </a-tooltip>
              </template>

              <!-- 固件版本 -->
              <template #firmware_version="{ record }">
                <span v-if="judgeCurrentType(EDeviceTypeName.Dock)">
                  <DeviceFirmwareUpgrade :device="record" class="table-flex-col" @device-upgrade="onDeviceUpgrade" />
                </span>
                <span v-else>
                  {{ record.firmware_version }}
                </span>
              </template>
              <!-- 状态 -->
              <template #status="{ text }">
                <span class="flex-row flex-align-center">
                  <span>{{ text }}</span>
                </span>
                <!-- <span class="flex-row flex-align-center" v-else>
              <span>离线</span>
            </span> -->
              </template>
              <!-- 操作 -->
              <template #action="{ record }">
                <div class="editable-row-operations">
                  <!-- 编辑态操作 -->
                  <div v-if="editableData[record.device_sn]">
                    <a-tooltip title="Confirm changes">
                      <span @click="save(record)" style="color: #28d445;">
                        <CheckOutlined />
                      </span>
                    </a-tooltip>
                    <a-tooltip title="Modification canceled">
                      <span @click="() => delete editableData[record.device_sn]" style="color: #e70102;">
                        <CloseOutlined />
                      </span>
                    </a-tooltip>
                  </div>
                  <!-- 非编辑态操作 -->
                  <div v-else class="flex-align-center flex-row" style="color: #2d8cf0">
                    <a-tooltip v-if="current.indexOf(EDeviceTypeName.Dock) !== -1" title="设备日志">
                      <CloudServerOutlined @click="showDeviceLogUploadRecord(record)" />
                    </a-tooltip>
                    <a-tooltip v-if="current.indexOf(EDeviceTypeName.Dock) !== -1" title="Hms Info">
                      <FileSearchOutlined @click="showHms(record)" />
                    </a-tooltip>
                    <!-- <a-tooltip title="返航">
                      <img class="ic" :src="editicon" alt="" @click="back(record)">
                    </a-tooltip> -->
                    <a-tooltip title="编辑">
                      <img class="ic" :src="editicon" alt="" @click="edit(record)">
                      <!-- <EditOutlined @click="edit(record)" /> -->
                    </a-tooltip>
                    <a-tooltip title="删除">
                      <img class="ic" :src="delicon" alt=""
                        @click="() => { deleteTip = true, deleteSn = record.device_sn }">
                      <!-- <DeleteOutlined @click="() => { deleteTip = true, deleteSn = record.device_sn }" /> -->
                    </a-tooltip>
                  </div>
                </div>
              </template>

            </a-table>
            <div class="fot">
              <a-pagination show-size-changer v-model:current="body.page" v-model:pageSize="body.page_size"
                :total="body.total" @showSizeChange="onShowSizeChange" :page-size-options="pageSizeOptions" />
            </div>
          </div>
          <a-modal v-model:visible="deleteTip" width="450px" :closable="false" centered
            :okButtonProps="{ danger: true }" @ok="unbind">
            <p class="pt10 pl20" style="height: 50px;">确认从工作空间删除设备?</p>
            <template #title>
              <div class="flex-row flex-justify-center">
                <span>删除设备</span>
              </div>
            </template>
          </a-modal>

          <!-- 设备升级 -->
          <DeviceFirmwareUpgradeModal title="设备升级" v-model:visible="deviceFirmwareUpgradeModalVisible"
            :device="selectedDevice" @ok="onUpgradeDeviceOk"></DeviceFirmwareUpgradeModal>

          <!-- 设备日志上传记录 -->
          <DeviceLogUploadRecordDrawer v-model:visible="deviceLogUploadRecordVisible" :device="currentDevice">
          </DeviceLogUploadRecordDrawer>

          <!-- hms 信息 -->
          <DeviceHmsDrawer v-model:visible="showHmsVisible" :device="currentDevice">
          </DeviceHmsDrawer>
        </div>
      </div>
    </div>
  </div>

</template>
<script lang="ts" setup>
import { AndroidOutlined, EditOutlined, CheckOutlined, CloseOutlined, DeleteOutlined, FileSearchOutlined, CloudServerOutlined } from '@ant-design/icons-vue'
import { getApp, getRoot } from '/@/root'
import state1 from '/@/assets/item/state1.png'
import state2 from '/@/assets/item/state2.png'
import { ColumnProps, TableState } from 'ant-design-vue/lib/table/interface'
import { h, onMounted, onUnmounted, reactive, ref, UnwrapRef, watch, computed } from 'vue'
import { IPage } from '/@/api/http/type'
import { BindBody, bindDevice, getBindingDevices, unbindDevice, updateDevice, getDeviceTopo, getUnreadDeviceHms, updateDeviceHms } from '/@/api/manage'
import { EDeviceTypeName, ELocalStorageKey } from '/@/types'

import { Device, DeviceFirmwareStatusEnum, OnlineDevice, EModeCode, OSDVisible, EDockModeCode, DeviceOsd, DrcStateEnum } from '/@/types/device'
import DeviceFirmwareUpgrade from '/@/components/devices/device-upgrade/DeviceFirmwareUpgrade.vue'
import DeviceFirmwareUpgradeModal from '/@/components/devices/device-upgrade/DeviceFirmwareUpgradeModal.vue'
import { useDeviceFirmwareUpgrade } from '/@/components/devices/device-upgrade/use-device-upgrade'
import { useDeviceUpgradeEvent } from '/@/components/devices/device-upgrade/use-device-upgrade-event'
import { DeviceCmdExecuteInfo, DeviceCmdExecuteStatus } from '/@/types/device-cmd'
import DeviceLogUploadRecordDrawer from '/@/components/devices/device-log/DeviceLogUploadRecordDrawer.vue'
import DeviceHmsDrawer from '/@/components/devices/device-hms/DeviceHmsDrawer.vue'
import { message, notification } from 'ant-design-vue'
import { useMyStore } from '/@/store'
import { useMqtt, DeviceTopicInfo } from '/@/components/g-map/use-mqtt'
import {
  useManualControl,
  KeyCode,
} from '/@/components/g-map/use-manual-control'
import noData from '/@/assets/icons/no-data.png'
import editicon from '/@/assets/item/编组 6@2x.png'
import delicon from '/@/assets/item/编组 10@2x.png'

const onlineDevices = reactive({ // 在线设备
  data: [] as OnlineDevice[]
})
const onlineDocks = reactive({ // 在线机场
  data: [] as OnlineDevice[]
})
const deviceInfo = computed(() => store.state.deviceState.deviceInfo)// 设备信息
const dockInfo = computed(() => store.state.deviceState.dockInfo)// 机场信息
const hmsInfo = computed({
  get: () => store.state.hmsInfo,
  set: (val) => {
    return val
  }
})
const hmsVisible = new Map<string, boolean>()// 存储信息（无人机和机场sn）
const root = getRoot()

interface DeviceData {
  device: Device[]
}
const body: IPage = {
  page: 1,
  total: 0,
  page_size: 50
}
const onShowSizeChange = (current: number, pageSize: number) => {
  body.page = current
  body.page_size = pageSize
  console.log(current, pageSize)
}

let timer = null

let checkAir = ''// 当前需要返航的无人机
const store = useMyStore()
const pageSizeOptions = ref<string[]>(['10', '20', '30', '40', '50'])
const loading = ref(true)
const deleteTip = ref<boolean>(false)
const deleteSn = ref<string>()
const columns: ColumnProps[] = [
  { title: '型号', dataIndex: 'device_name', width: 100, className: 'titleStyle' },
  { title: 'SN', dataIndex: 'device_sn', width: 100, className: 'titleStyle', ellipsis: true, slots: { customRender: 'sn' } },
  {
    title: '名称',
    dataIndex: 'nickname',
    width: 100,
    sorter: (a: Device, b: Device) => a.nickname.localeCompare(b.nickname),
    className: 'titleStyle',
    // ellipsis: true,
    slots: { customRender: 'nickname' }
  },
  // { title: '固件版本', dataIndex: 'firmware_version', width: 150, className: 'titleStyle', slots: { customRender: 'firmware_version' } },
  { title: '电量', dataIndex: 'battery', width: 50, className: 'titleStyle', slots: { customRender: 'battery' } },
  // { title: '状态', dataIndex: 'status', width: 100, className: 'titleStyle', slots: { customRender: 'status' } },
  { title: '详细状态', dataIndex: 'airstatus', width: 100, className: 'titleStyle', slots: { customRender: 'airstatus' } },
  // {
  //   title: '项目',
  //   dataIndex: 'workspace_name',
  //   width: 100,
  //   className: 'titleStyle',
  //   ellipsis: true,
  //   slots: { customRender: 'workspace' },
  //   customRender: ({ text, record, index }) => {
  //     const obj = {
  //       children: text,
  //       props: {} as any,
  //     }
  //     if (current.value.indexOf(EDeviceTypeName.Dock) !== -1) {
  //       if (record.domain === EDeviceTypeName.Aircraft) {
  //         obj.children = ''
  //       }
  //     }
  //     return obj
  //   }
  // },
  // { title: '创建时间', dataIndex: 'bound_time', width: 150, sorter: (a: Device, b: Device) => a.bound_time.localeCompare(b.bound_time), className: 'titleStyle' },
  { title: '上次在线时间', dataIndex: 'login_time', width: 150, sorter: (a: Device, b: Device) => a.login_time.localeCompare(b.login_time), className: 'titleStyle' },
  {
    title: '操作',
    dataIndex: 'actions',
    width: 100,
    className: 'titleStyle',
    slots: { customRender: 'action' }
  },
]

// 飞行控制相关
const deviceState = computed(() => store.state.deviceState) // 设备总体信息
// 用于mqtt通信的设备topic
const deviceTopicInfo: DeviceTopicInfo = reactive({
  // sn: checkAir,
  sn: deviceState.value.currentSn,
  pubTopic: '',
  subTopic: '',
})

// checkAir  返航飞机sn
const drcState = computed(() => {
  return store.state.deviceState?.dockInfo[checkAir]?.link_osd?.drc_state ===
    DrcStateEnum.CONNECTED
})

const flightController = ref(drcState.value)

const { handleEmergencyGoon, handleEmergencyStop, handleEmergencyReturn, handleEmergencySuspend } =
  useManualControl(deviceTopicInfo, flightController, store)

// 返航指令
const back = (item: any) => {
  console.log(item, 'item')
  checkAir = item.sn
  console.log(drcState, 'drcState?')
  console.log(store.state.deviceState, 'store.state.deviceState?')

  // checkAir = record.device_sn
  // console.log(drcState.value, '这是？')
  // mqtt返航
  // handleEmergencyReturn()
}

// 返航部分结束

const expandIcon = (props: any) => {
  if (judgeCurrentType(EDeviceTypeName.Dock) && !props.expanded) {
    return h('div',
      {
        style: 'border-left: 2px solid rgb(200,200,200); border-bottom: 2px solid rgb(200,200,200); height: 16px; width: 16px; float: left;',
        class: 'mt-5 ml0',
      })
  }
}

const rowClassName = (record: any, index: number) => {
  const className = []
  if ((index & 1) === 0) {
    className.push('table-striped')
  }
  if (record.domain !== EDeviceTypeName.Dock) {
    className.push('child-row')
  }
  return className.toString().replaceAll(',', ' ')
}

const expandRows = ref<string[]>([])
const data = reactive<DeviceData>({
  device: []
})

const paginationProp = reactive({
  pageSizeOptions: ['20', '50', '100'],
  showQuickJumper: true,
  showSizeChanger: true,
  pageSize: 50,
  current: 1,
  total: 0
})

// 获取分页信息
function getPaginationBody () {
  return {
    page: paginationProp.current,
    page_size: paginationProp.pageSize
  } as IPage
}

const rowSelection = {
  onChange: (selectedRowKeys: (string | number)[], selectedRows: []) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
  },
  onSelect: (record: any, selected: boolean, selectedRows: []) => {
    console.log(record, selected, selectedRows)
  },
  onSelectAll: (selected: boolean, selectedRows: [], changeRows: []) => {
    console.log(selected, selectedRows, changeRows)
  },
  getCheckboxProps: (record: any) => ({
    disabled: judgeCurrentType(EDeviceTypeName.Dock) && record.domain !== EDeviceTypeName.Dock,
    style: judgeCurrentType(EDeviceTypeName.Dock) && record.domain !== EDeviceTypeName.Dock ? 'display: none' : ''
  }),
}
type Pagination = TableState['pagination']

const workspaceId: string = localStorage.getItem(ELocalStorageKey.WorkspaceId) || ''
let editableData: UnwrapRef<Record<string, Device>> = reactive({})
const current = ref([EDeviceTypeName.Aircraft])

function judgeCurrentType (type: EDeviceTypeName): boolean {
  return current.value.indexOf(type) !== -1
}

// 设备升级
const {
  deviceFirmwareUpgradeModalVisible,
  selectedDevice,
  onDeviceUpgrade,
  onUpgradeDeviceOk
} = useDeviceFirmwareUpgrade(workspaceId)

function onDeviceUpgradeWs (payload: DeviceCmdExecuteInfo) {
  updateDevicesByWs(data.device, payload)
}

function updateDevicesByWs (devices: Device[], payload: DeviceCmdExecuteInfo) {
  if (!devices || devices.length <= 0) {
    return
  }
  for (let i = 0; i < devices.length; i++) {
    if (devices[i].device_sn === payload.sn) {
      if (!payload.output) return
      const { status, progress, ext } = payload.output
      if (status === DeviceCmdExecuteStatus.Sent || status === DeviceCmdExecuteStatus.InProgress) { // 升级中
        const rate = ext?.rate ? (ext.rate / 1024).toFixed(2) + 'kb/s' : ''
        devices[i].firmware_status = DeviceFirmwareStatusEnum.DuringUpgrade
        devices[i].firmware_progress = (progress?.percent || 0) + '% ' + rate
      } else { // 终态：成功，失败，超时
        if (status === DeviceCmdExecuteStatus.Failed || status === DeviceCmdExecuteStatus.Timeout) {
          notification.error({
            message: `(${payload.sn}) Upgrade failed`,
            description: `Error Code: ${payload.result}`,
            duration: null
          })
        }
        // 拉取列表
        getDevices(current.value[0], true)
      }
      return
    }
    if (devices[i].children) {
      updateDevicesByWs(devices[i].children || [], payload)
    }
  }
}

useDeviceUpgradeEvent(onDeviceUpgradeWs)

// 更新数据
function updateDeviceData (domain: number, closeLoading?: boolean) {
  getBindingDevices(workspaceId, body, domain).then(res => {
    if (res.code !== 0) {
      return
    }

    const resData: Device[] = res.data.list
    expandRows.value = []
    resData.forEach((val: any) => {
      if (val.children) {
        val.children = [val.children]
      }
      if (judgeCurrentType(EDeviceTypeName.Dock)) {
        expandRows.value.push(val.device_sn)
      }
    })

    // data.device.forEach((item: any) => {
    //   item.status = EDockModeCodeTranslate(EModeCode[value.deviceInfo[item.device_sn]?.mode_code]) || '离线'
    // })

    resData.forEach(newDevice => {
      const existingDevice = data.device.find(d => d.device_sn === newDevice.device_sn)
      if (existingDevice) {
        existingDevice.status = newDevice.status
      }
    })
  })
}

// 获取设备列表信息
function getDevices (domain: number, closeLoading?: boolean) {
  if (!closeLoading) {
    loading.value = true
  }
  getBindingDevices(workspaceId, body, domain).then(res => {
    if (res.code !== 0) {
      return
    }

    const resData: Device[] = res.data.list
    expandRows.value = []
    resData.forEach((val: any) => {
      if (val.children) {
        val.children = [val.children]
      }
      if (judgeCurrentType(EDeviceTypeName.Dock)) {
        expandRows.value.push(val.device_sn)
      }
    })
    // 添加电量
    resData.forEach((item: any) => {
      item.battery = 0
    })

    data.device = resData

    console.log(res.data, '获取绑定设备列表信息')
    console.log(store.state.deviceState, '获取绑定设备列表信息')

    body.total = res.data.pagination.total
    body.page = res.data.pagination.page
    body.page_size = res.data.pagination.page_size
    loading.value = false
  })
}

function EModeCodeTranslate (str: any) { // 翻译 无人机或飞行任务的状态
  switch (str) {
    case 'Standby':
      return '待机中'
    case 'Preparing':
      return '准备中'
    case 'Ready':
      return '就绪'
    case 'Manual':
      return '手动'
    case 'Automatic':
      return '自动'
    case 'Waypoint':
      return '航点飞行'
    case 'Panoramic':
      return '全景拍摄'
    case 'Active_Track':
      return '跟踪中'
    case 'ADS_B':
      return '避让中'
    case 'Return_To_Home':
      return '返航中'
    case 'Landing':
      return '降落中'
    case 'Forced_Landing':
      return '强制降落'
    case 'Three_Blades_Landing':
      return '三桨降落'
    case 'Upgrading':
      return '升级中'
    case 'Disconnected':
      return '已断开'
    default:
      return str
  }
}
function EDockModeCodeTranslate (str: any) { // 翻译  设备（比如无人机或地面控制站）的连接和工作状态
  switch (str) {
    case 'Disconnected':
      return '断开连接'
    case 'Idle':
      return '空闲'
    case 'Debugging':
      return '调试'
    case 'Remote_Debugging':
      return '远程调试'
    case 'Upgrading':
      return '升级中'
    case 'Working':
      return '工作中'
    default:
      return str
  }
}
watch(() => store.state.deviceState, (value) => {
  data.device.forEach((item: any) => {
    item.airstatus = EModeCodeTranslate(EModeCode[value.deviceInfo[item.device_sn]?.mode_code]) || '已断开'
  })

  data.device.forEach((item: any) => {
    if (item.status) {
      item.battery = value.deviceInfo[item.device_sn]?.battery.capacity_percent || 0
    }
  })
}, {
  immediate: true,
  deep: true
})

function refreshData (page: Pagination) {
  getDevices(current.value[0])
}

// 编辑
function edit (record: Device) {
  console.log(record, '编辑')

  editableData[record.device_sn] = record
}

// 保存
function save (record: Device) {
  delete editableData[record.device_sn]
  updateDevice({ nickname: record.nickname }, workspaceId, record.device_sn)
}

// 删除
function showDeleteTip (sn: any) {
  deleteTip.value = true
}

// 解绑
function unbind () {
  deleteTip.value = false
  unbindDevice(deleteSn.value?.toString()!).then(res => {
    if (res.code !== 0) {
      return
    }
    getDevices(current.value[0])
  })
}

// 选择设备(无人机还是机场)
function select (item: any) {
  editableData = reactive({})
  getDevices(item.key)
}

const currentDevice = ref({} as Device)
// 设备日志
const deviceLogUploadRecordVisible = ref(false)
function showDeviceLogUploadRecord (dock: Device) {
  deviceLogUploadRecordVisible.value = true
  currentDevice.value = dock
}

const showHmsVisible = ref(false)
function showHms (dock: Device) {
  showHmsVisible.value = true
  currentDevice.value = dock
}

// 获取在线内容
function getOnlineTopo () {
  getDeviceTopo(workspaceId).then((res) => { // Get Device Topo 获取工作中联机设备的拓扑表
    if (res.code !== 0) {
      return
    }
    onlineDevices.data = []
    onlineDocks.data = []
    // console.log(res, '工作中联机设备的拓扑表')

    res.data.forEach((gateway: any) => { // 遍历机场
      const child = gateway.children// 机场的无人机
      const device: OnlineDevice = { // 无人机信息
        model: child?.device_name, // 无人机名称
        callsign: child?.nickname, // 无人机昵称
        sn: child?.device_sn, // 无人机sn
        mode: EModeCode.Disconnected, // 代表断开连接
        gateway: { // 网关
          model: gateway?.device_name, // 机场名称
          callsign: gateway?.nickname, // 机场昵称
          sn: gateway?.device_sn, // 机场sn
          domain: gateway?.domain// 领域？3
        },
        payload: []// 负载
      }
      child?.payloads_list.forEach((payload: any) => { // 遍历无人机的负载列表
        device.payload.push({ // 存放到无人机信息对象中
          index: payload.index,
          model: payload.model,
          payload_name: payload.payload_name, // 负载名称：xxx相机
          payload_sn: payload.payload_sn,
          control_source: payload.control_source, // ?
          payload_index: payload.payload_index// ?
        })
      })
      if (EDeviceTypeName.Dock === gateway.domain) { // 如果设备类型（机场）？==当前设备类型
        hmsVisible.set(device.sn, false)
        hmsVisible.set(device.gateway.sn, false)
        onlineDocks.data.push(device)
      }
      if (gateway.status && EDeviceTypeName.Gateway === gateway.domain) {
        onlineDevices.data.push(device)
      }
    })

    console.log(onlineDevices.data, '在线设备')
    console.log(onlineDocks.data, '在线机场')
  })
}
// 获取未读信息？
function getUnreadHms (sn: string) {
  getUnreadDeviceHms(workspaceId, sn).then(res => {
    if (res.data.length !== 0) {
      hmsInfo.value[sn] = res.data
    }
  })
  console.info(hmsInfo.value)
}
// 获取在线设备信息
function getOnlineDeviceHms () {
  const snList = Object.keys(dockInfo.value)
  if (snList.length === 0) {
    return
  }
  snList.forEach(sn => {
    getUnreadHms(sn)
  })
  const deviceSnList = Object.keys(deviceInfo.value)
  if (deviceSnList.length === 0) {
    return
  }
  deviceSnList.forEach(sn => {
    getUnreadHms(sn)
  })
}
// 读消息
function readHms (visiable: boolean, sn: string) {
  if (!visiable) {
    updateDeviceHms(workspaceId, sn).then(res => {
      if (res.code === 0) {
        delete hmsInfo.value[sn]
      }
    })
  }
}

onMounted(() => {
  getOnlineTopo()
  // let tempHight = document.querySelector('.flex-display')// 总高
  // let tempHight2 = document.querySelector('.fot')// 底部翻页器
  // let tempHight3 = document.querySelector('.ant-table-header')// table头部
  // let tempHight4 = document.querySelector('.ant-table-wrapper')// 上边距
  // let finalH = tempHight.offsetHeight - tempHight2.offsetHeight - tempHight3.offsetHeight + tempHight4.offsetTop
  // document.documentElement.style.setProperty('--aheight', `${finalH}px`) // 修改CSS变量的值

  // window.onresize = () => {
  //   tempHight = document.querySelector('.flex-display')// 总高
  //   tempHight2 = document.querySelector('.fot')// 底部翻页器
  //   tempHight3 = document.querySelector('.ant-table-header')// table头部
  //   tempHight4 = document.querySelector('.ant-table-wrapper')// 上边距
  //   finalH = tempHight.offsetHeight - tempHight2.offsetHeight - tempHight3.offsetHeight + tempHight4.offsetTop
  //   document.documentElement.style.setProperty('--aheight', `${finalH}px`) // 修改CSS变量的值
  // }
  getDevices(current.value[0])
  timer = setInterval(() => {
    updateDeviceData(current.value[0])
  }, 5000)

  setTimeout(() => { // 每隔3s获取一次在线信息
    watch(() => store.state.deviceStatusEvent,
      data => {
        getOnlineTopo()
        if (data.deviceOnline.sn) {
          getUnreadHms(data.deviceOnline.sn)
        }
      },
      {
        deep: true
      }
    )
    getOnlineDeviceHms()
  }, 3000)
})
onUnmounted(() => {
  if (timer) {
    clearInterval(timer)
  }
})
</script>
<style lang="scss" scoped>
.a {
  display: flex;

  .project-app-wrapper {
    width: 380px;
    color: #000;
    flex-direction: column;
    padding: 15px;

    .wrapper-header {
      width: 100%;
      height: 50px;
      background: #fff;
      padding: 18px;
      text-align: start;
      display: flex;
      align-items: center;
      border-radius: 5px;
      margin-bottom: 10px;
      font-size: 18px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #000000;
    }

    .panel {
      .panel-item {
        // height: 90px; width: 250px; margin-bottom: 10px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        // cursor: pointer;
        margin-bottom: 6px;
        border-radius: 6px;
        overflow: hidden;

        .item-name {
          width: 100%;
          display: flex;
          align-items: center;
          height: 30px;
          line-height: 30px;
          background-color: #fff;

          .item-name-icon {
            width: 8px;
            height: 8px;
            background-color: #00D9FF;
            margin-left: 15px;
          }

          .item-name-text {
            font-size: 16px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #000000;
            margin-left: 7px;
          }

        }

        .item-body {
          width: 100%;
          background-color: #fdfdfd;
          // padding: 16px 64px 16px 23px;
          padding: 10px;
          display: flex;
          justify-content: space-between;
          .item-right{
            width: 50px;
            background-color: #f2f2f2;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .item-body-state {
            width: 230px;
            background-color: #F2F2F2;
            margin-bottom: 5px;
            display: flex;
            height: 35px;
            align-items: center;

            img {
              margin-left: 10px;
              width: 12px;
              height: 12px;
            }

            .item-body-state-text {
              margin-left: 3px;
              font-size: 14px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #545454;
            }

            .item-body-state-line {
              margin-left: 10px;
              width: 1px;
              height: 16px;
              background-color: #979797;
            }

            .item-body-state-right {
              margin-left: 10px;
              font-size: 14px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #545454;
            }
          }
        }

      }
    }

    .wrapper-body {
      .body-item {
        // background-color: #FDFDFD;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        cursor: pointer;
        margin-bottom: 10px;
        border-radius: 6px;
        overflow: hidden;

        .item-name {
          width: 100%;
          display: flex;
          align-items: center;
          height: 40px;
          line-height: 40px;
          background-color: #fff;

          .item-name-icon {
            width: 8px;
            height: 8px;
            background-color: #00D9FF;
            margin-left: 23px;
          }

          .item-name-text {
            font-size: 16px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #000000;
            margin-left: 7px;
          }

        }

        .item-body {
          width: 100%;
          background-color: #fdfdfd;
          padding: 16px 64px 16px 23px;

          .item-body-state {
            width: 300px;
            background-color: #F2F2F2;
            margin-bottom: 8px;
            display: flex;
            height: 35px;
            align-items: center;

            img {
              margin-left: 17px;
              width: 12px;
              height: 12px;
            }

            .item-body-state-text {
              margin-left: 3px;
              font-size: 14px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #545454;
            }

            .item-body-state-line {
              margin-left: 17px;
              width: 1px;
              height: 16px;
              background-color: #979797;
            }

            .item-body-state-right {
              margin-left: 17px;
              font-size: 14px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #545454;
            }
          }
        }
      }
    }
  }

  .r {
    width: 1440px;

    :deep(.ant-table-wrapper) {
      margin-top: 15px;
    }

    :deep(.fot) {
      padding: 20px 0;
    }

    :root {
      --aheight: 100px
    }

    :deep(.ant-table-body) {
      // height: 580px;
      max-height: none !important;
      height: var(--aheight);
    }

    :deep(.ant-table-body::-webkit-scrollbar, .info::-webkit-scrollbar) {
      height: 9px;
      width: 9px;
      background: #3A63F31F;
      opacity: 0.12;
    }

    :deep(.ant-table-body::-webkit-scrollbar-thumb, .info::-webkit-scrollbar-thumb) {
      border-radius: 9px;
      background: #3A63F3;
    }

    :deep(.ant-pagination-item-active) {
      background: #3A63F3;
      border-radius: 2px;
      border-color: #3A63F3;

      a {
        font-size: 16px;
        font-weight: 500;
        color: #FFFFFF;
      }

    }

    :deep(.ant-menu-horizontal) {
      height: 50px;
      border-radius: 6px;
    }

    .all {
      background-color: #f0f2f5;

      height: 100%;
      padding: 15px 15px 15px 0;

      .all-header {

        display: flex;
        align-items: center;
        height: 50px;
        background-color: #fff;
        margin-bottom: 10px;

        .all-header-icon {
          width: 8px;
          height: 8px;
          background-color: #00D9FF;
          margin-left: 23px;
        }

        .all-header-text {
          font-size: 14px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #000000;
          margin-left: 16px;
        }
      }

      .all-body {
        height: calc(100% - 60px);
        // background-color: #fff;
        background-color: #f0f2f5;
        border-radius: 5px;
      }

      .flex-display {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 70px);

        .fot {
          text-align: right;
          background-color: #fff;
        }
      }
    }

    .device-table-wrap {
      height: 500px;

      .editable-row-operations {
        div>span {
          margin-right: 10px;
        }

        .ic {
          width: 24px;
          height: 24px;
          margin-right: 19px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
