
import {
  ref,
  watch,
  computed,
  onUnmounted,
} from 'vue'
import { useMyStore } from '/@/store'
import { postDrc } from '/@/api/drc'
import {
  UranusMqtt,
} from '/@/mqtt'

type StatusOptions = {
  status: 'close';
  event?: CloseEvent;
} | {
  status: 'open';
  retryCount: number;
} | {
  status: 'pending';
}
// 原始连接mqtt
export function useConnectMqtt () {
  const store = useMyStore()
  const dockOsdVisible = computed(() => {
    return store.state.osdVisible && store.state.osdVisible.visible && store.state.osdVisible.is_dock
  })
  const mqttState = ref<UranusMqtt | null>(null)

  // 监听已打开的设备小窗 窗口数量
  watch(() => dockOsdVisible.value, async (val) => {
    // console.log(store.state.osdVisible)
    // console.log(store.state.osdVisible.visible)// true
    // console.log(store.state.osdVisible.is_dock)// true

    console.log('监听val', val)// true or false

    // 1.打开小窗
    // 2.设备拥有飞行控制权
    // 3.请求建立mqtt连接的认证信息
    if (val) {
      if (mqttState.value) return
      const result = await postDrc({})// 获取 mqtt 连接认证
      if (result?.code === 0) {
        console.log(result, '获取 mqtt 连接认证')// 在打开控制面板的时候就获取了

        const { address, client_id, username, password, expire_time } = result.data
        // @TODO: 校验 expire_time
        mqttState.value = new UranusMqtt(address, {
          clientId: client_id,
          username,
          password,
        })
        mqttState.value?.initMqtt()
        mqttState.value?.on('onStatus', (statusOptions: StatusOptions) => {
          // @TODO: 异常case
        })

        store.commit('SET_MQTT_STATE', mqttState.value)
        store.commit('SET_CLIENT_ID', client_id)
      }
      // @TODO: 认证失败case
      return
    }
    // 关闭所有小窗后
    // 1.销毁mqtt连接重置mqtt状态
    if (mqttState?.value) {
      mqttState.value?.destroyed()
      mqttState.value = null
      store.commit('SET_MQTT_STATE', null)
      store.commit('SET_CLIENT_ID', '')
    }
  }, { immediate: true })

  onUnmounted(() => {
    mqttState.value?.destroyed()
  })
}
// 新连接mqtt：监听是否立即飞行
export async function useConnectMqtt2 () {
  const store = useMyStore()
  let onlyOne = true
  const deviceOnline = computed(() => {
    return store.state.takeoffPercent
  })
  const mqttState = ref<UranusMqtt | null>(null)
  console.log('监听中,mqtt连接获取中')
  // 1.原来是通过监听计划执行百分比，由于手动操作中无法获取，现在直接获取
  // 2.只执行一次
  // 3.请求建立mqtt连接的认证信息
  if (onlyOne) {
    // if (deviceOnline.value.number > 25 && onlyOne) {
    onlyOne = false
    if (mqttState.value) return
    const result = await postDrc({})// 获取 mqtt 连接认证
    if (result?.code === 0) {
      console.log(result, '真正获取 mqtt 连接认证')//
      const { address, client_id, username, password, expire_time } = result.data
      // @TODO: 校验 expire_time
      mqttState.value = new UranusMqtt(address, {
        clientId: client_id,
        username,
        password,
      })
      mqttState.value?.initMqtt()
      mqttState.value?.on('onStatus', (statusOptions: StatusOptions) => {
        // @TODO: 异常case
      })

      store.commit('SET_MQTT_STATE', mqttState.value)
      store.commit('SET_CLIENT_ID', client_id)
    }
    // @TODO: 认证失败case
    return
  }
  // // 关闭所有小窗后
  // // 1.销毁mqtt连接重置mqtt状态
  if (mqttState?.value && deviceOnline.value.number > 95) {
    onlyOne = true
    mqttState.value?.destroyed()
    mqttState.value = null
    store.commit('SET_MQTT_STATE', null)
    store.commit('SET_CLIENT_ID', '')
    console.log('销毁或重置mqtt')
  }
  // }, { deep: true })

  onUnmounted(() => {
    mqttState.value?.destroyed()
  })
}
