import { InjectionKey } from 'vue'
import { ActionTree, createStore, GetterTree, MutationTree, Store, StoreOptions, useStore } from 'vuex'
import { EDeviceTypeName } from '../types'
import { Device, DeviceHms, DeviceOsd, DeviceStatus, DockOsd, GatewayOsd, OSDVisible } from '../types/device'
import { getLayers } from '/@/api/layer'
import { LayerType } from '/@/types/mapLayer'
import { WaylineFile } from '/@/types/wayline'
import { DevicesCmdExecuteInfo } from '/@/types/device-cmd'
import { getRoot } from '/@/root'
import { v4 as uuidv4 } from 'uuid'

import createPersistedstate from 'vuex-persistedstate'
import deviceModule from '/@/store/modules/device'
import authorityModule from '/@/store/modules/authority'

const initStateFunc = () => ({
  littleWaylineLength: 0, // 用来记录小地图航线长度
  takeoffPercent: { // 当前执行任务进度百分比及状态
    number: 0,
    status: ''
  }, // 用于记录起飞完成进度百分比
  waylineIsEdit: {
    bool: false,
    id: 0
  }, // 代表航线文件是否为编辑进入
  actionGroupId: 0, // 动作组id
  ifControlOn: 0, // 用于判断控制面板是否生成

  waylineForm: { // 用于存储航线编辑时传给createWaylineLeft的数据
    author: '默认名称',

    executeHeightMode: 'relativeToStartPoint', // 执行高度模式 way

    globalHeight: 120, // 海拔 tem
    ellipsoidHeight: 10, // 全局航线高度（WGS84椭球高度） tem

    autoFlightSpeed: '1', // 全局航线飞行速度 1-15

    flyToWaylineMode: 'safely', // 垂直爬升安全高度
    takeOffSecurityHeight: 1.5, // 安全起飞高度
    takeOffRefPoint: '', // 参考起飞点

    waypointHeadingMode: 'smoothTransition', // 飞行器偏航角模式
    waypointHeadingAngle: '0', // 偏航角模式 自定义
    waypointPoiPoint: '0.000000,0.000000,0.000000', // 偏航角模式 兴趣点
    waypointHeadingPathMode: 'followBadArc', // 飞行器偏航角转动方向（枚举）

    gimbalPitchMode: 'manual', // 云台俯仰角控制模式 tem

    globalWaypointTurnMode: 'toPointAndStopWithDiscontinuityCurvature', // 全局航点类型  tem
    globalUseStraightLine: '1', // 全局航段轨迹是否尽量贴合直线  tem

    globalRTHHeight: 100, // 返航高度 非必须 miss

    finishAction: 'goHome', // 航线结束动作      miss
    exitOnRCLost: 'goContinue', // 失控是否继续执行航线 miss
    executeRCLostAction: 'goBack', // 失控动作类型 miss
  },
  waylineLength: 0, // 用于记录航线的长度，方便展示航线时进行删除
  isDrawOver: false, // 用于标记标注是否画过了
  mouseToolOpen: false, // 用于标记标注是否处于绘图中
  folderId: '0', // 用于存储选择的文件夹id
  isVideo: false, // 用来识别是否已经有了开始录像动作
  pointId: 0,
  waylineFormMsg: {
    name: '',
    folder: '',
    airplan: '',
    uav: ''
  },
  waylinePointMsg: [] as any, // 每个航点信息
  isViewOk: false,
  projectMsg: {
    nick: '',
    remark: '',
    // longitude: 113.943225499,
    // latitude: 22.577673716,
    longitude: 0,
    latitude: 0,
  }, // 项目信息
  waylineMSG: {} as any, // 存储查看航线信息
  waylineIndex: 0,
  waylinePoints: [],
  waylineMarkers: [],
  waylineCoordinates: [],
  Layers: [
    {
      name: 'default',
      id: '',
      is_distributed: true,
      elements: [],
      is_check: false,
      is_select: false,
      type: 1,
      edit: false,
      show: false
    },
    {
      name: 'share',
      id: '',
      is_distributed: true,
      elements: [],
      is_check: false,
      is_select: false,
      type: 2,
      edit: false,
      show: false
    }
  ],
  layerBaseInfo: {} as {
    [key: string]: string
  },
  drawVisible: false,
  createWaylineVisible: '1',
  coverList: [

  ] as any,
  wsEvent: {
    mapElementCreat: {},
    mapElementUpdate: {},
    mapElementDelete: {}
  },
  deviceStatusEvent: {
    deviceOnline: {} as DeviceStatus,
    deviceOffline: {}
  },
  markerInfo: {
    coverMap: {} as {
      [sn: string]: any
    },
    pathMap: {} as {
      [sn: string]: any[]
    }
  },
  deviceState: {
    // remote controller, dock
    // 储网关设备（如远程控制器或机场）的信息
    gatewayInfo: {} as {
      [sn: string]: GatewayOsd
    },
    // 存储无人机设备的相关信息。
    deviceInfo: {} as {
      [sn: string]: DeviceOsd
    },
    // 存储机场（Dock）的相关信息。
    dockInfo: {} as {
      [sn: string]: DockOsd
    },
    currentSn: '',
    currentType: -1
  },
  osdVisible: { // osd 显示设备相关信息
    sn: '',
    callsign: '',
    model: '',
    visible: false,
    gateway_sn: '',
    is_dock: false,
    payloads: null
  } as OSDVisible,

  dockInfo: {

  } as Device,
  hmsInfo: {} as {
    [sn: string]: DeviceHms[]
  },
  // 机场指令执行状态信息
  devicesCmdExecuteInfo: {
  } as DevicesCmdExecuteInfo,
  mqttState: null as any, // mqtt 实例
  clientId: '', // mqtt 连接 唯一客户端id
})

export type RootStateType = ReturnType<typeof initStateFunc>

const getters: GetterTree<RootStateType, RootStateType> = {
}
const mutations: MutationTree<RootStateType> = {

  CHANGE_PERCENT(state, obj) {
    state.takeoffPercent = { ...obj }
    console.log('设置进度表')
  },
  CHANGE_WALINETYPE(state, obj) {
    state.waylineIsEdit = { ...obj }
  },
  SET_ACTIONGROUPID(state, number) {
    if (number == 0) {
      state.actionGroupId = 0
    } else {
      state.actionGroupId++
    }
  },
  SET_IS_CONTROL_ON(state, bool) {
    state.ifControlOn = bool
  },

  SET_WAYLINEFORM(state, form) {
    state.waylineForm = { ...form }
  },
  SET_LITTLEWAYLINE_LENGTH(start, num) { // 设置用于展示的小地图航线长度
    start.littleWaylineLength = num
  },
  SET_WAYLINE_LENGTH(start, num) { // 设置用于展示的航线长度
    start.waylineLength = num
  },
  SET_ISDRAWOVER(state) {
    state.isDrawOver = true
  },
  SET_MOUSETOOLOPEN(state, boll) {
    state.mouseToolOpen = boll
  },
  SET_POINT_ID(state, bool?) {
    if (bool) {
      state.pointId = 1
    } else {
      state.pointId++
    }
  },
  CLEAR_WAYLINEFORM_MSG(state) {
    state.waylineFormMsg = {
      name: '',
      folder: '',
      airplan: '',
      uav: ''
    }
  },
  CLEAR_WAYLINE_MSG(state) {
    state.waylinePointMsg = []
  },
  SET_WAYLINEFORM_MSG(state, msg) { // 创建新航线表单信息
    state.waylineFormMsg = { ...msg }
    window.sessionStorage.setItem('waylineFormMsg', JSON.stringify(state.waylineFormMsg))
  },
  SET_WAYLINE_PHOTO(state, msg) { // 添加航线航点拍照动作
    const generatedUUID1: string = uuidv4()
    const generatedUUID2: string = uuidv4()

    state.waylinePointMsg.forEach((item: any) => {
      if (item.index === msg.index) {
        console.log('将要添加动作的航点id：', msg.index)

        item.action.push({
          'wpml:actionActuatorFunc': 'orientedShoot',
          'wpml:actionId': item.action.length,
          'wpml:actionActuatorFuncParam': {
            'wpml:payloadPositionIndex': '0', // 负载挂载位置
            'wpml:gimbalPitchRotateAngle': msg.pitch,
            'wpml:gimbalRollRotateAngle': '0', // roll角度
            'wpml:gimbalYawRotateAngle': msg.yaw, // 云台Yaw转动角度
            'wpml:focusX': '0',
            'wpml:focusY': '0',
            'wpml:focusRegionWidth': '0', // 目标选中框宽
            'wpml:focusRegionHeight': '0', // 目标选中框高
            'wpml:focalLength': '120', // 变焦焦距
            'wpml:aircraftHeading': msg.yaw, // 飞行器目标偏航角
            'wpml:accurateFrameValid': '0', // 是否框选精准复拍目标    罪魁祸首，只能是0
            'wpml:useGlobalPayloadLensIndex': '1', // 是否启用全局设置
            'wpml:payloadLensIndex': 'zoom', // 拍摄照片存储类型！！！！！！！！！！！！！！！
            'wpml:targetAngle': '0', // 目标框角度
            'wpml:actionUUID': generatedUUID1,
            'wpml:imageWidth': '0',
            'wpml:imageHeight': '0',
            'wpml:AFPos': '0',
            'wpml:gimbalPort': '0',
            'wpml:orientedCameraType': '52', // 相机类型
            'wpml:orientedFilePath': generatedUUID2, // 照片文件路径
            'wpml:orientedFileMD5': '', // ????
            'wpml:orientedFileSize': '0',
            'wpml:orientedPhotoMode': 'normalPhoto', // 拍照模式！！！！！！！！！

          }
        })
      }
    })
  },
  SET_WALINE_VIDEO(state, msg) { // 添加航线录像动作
    if (!state.isVideo) {
      state.waylinePointMsg.forEach((item: any) => {
        if (item.index === msg.index) {
          item.action.push({
            'wpml:actionActuatorFunc': 'startRecord',
            'wpml:actionId': item.action.length,
            'wpml:actionActuatorFuncParam': {
              'wpml:payloadPositionIndex': '0', // 负载挂载位置
              'wpml:useGlobalPayloadLensIndex': '1',
              'wpml:payloadLensIndex': 'zoom'// 视频存储类型
            }
          })
        }
      })
      state.isVideo = true
    } else { // 添加停止录像动作
      state.waylinePointMsg.forEach((item: any) => {
        if (item.index === msg.index) {
          item.action.push({
            'wpml:actionActuatorFunc': 'stopRecord',
            'wpml:actionId': item.action.length,
            'wpml:actionActuatorFuncParam': {
              'wpml:payloadPositionIndex': '0', // 负载挂载位置

            }
          })
        }
      })
      state.isVideo = false
    }
  },
  SET_WAYLINE_MSG(state, msg) { // 在空格画点之后存储信息
    // state.waylinePointMsg.push(msg)
    state.waylinePointMsg.push({
      index: msg.index,
      Point: {
        longitude: Number(msg.position.lng),
        latitude: Number(msg.position.lat),
      },
      height: msg.height, // 航点高度（海拔高度？）
      ellipsoidHeight: msg.dixingHeight, // 航点椭球高度(即相对地面高度)
      waypointTurnMode: 'toPointAndStopWithDiscontinuityCurvature', // 航点类型
      waypointHeadingMode: 'smoothTransition', // 飞行器偏航角模式
      mappingHeadingAngle: '', // 飞行器偏航角 0-360
      waypointSpeed: 5, // 航点飞行速度
      useGlobalHeight: true, // 是否使用全局高度
      useGlobalTurnParam: true, // 是否使用全局航点类型（全局航点转弯模式）
      useGlobalHeadingParam: true, // 是否使用全局偏航角模式参数
      waypointHeadingAngle: 0, // 飞行器偏航角度
      useGlobalSpeed: true, // 是否全局速度
      waypointHeadingPathMode: 'followBadArc', // 飞行器偏航角转动方向
      action: [],

      // actionhover: { // hover悬停SA
      //   actionActuatorFunc: '',
      //   actionId: '',
      //   actionActuatorFuncParam: {
      //     'wpml:hoverTime': ''
      //   }
      // },
      // actionrotateYaw: { // rotateYaw飞行器偏航
      //   actionActuatorFunc: '',
      //   actionId: '',
      //   actionActuatorFuncParam: {
      //     'wpml:aircraftHeading': '', // 飞行器目标偏航角 -180 180
      //     'wpml:aircraftPathMode': ''
      //   }
      // },
      // actiongimbalRotate: { // rotateYaw飞行器偏航
      //   actionActuatorFunc: '',
      //   actionId: '',
      //   actionActuatorFuncParam: {
      //     'wpml:gimbalRotateTime': 0,
      //     'wpml:gimbalHeadingYawBase': 'north',
      //     'wpml:gimbalRotateTimeEnable': 0,
      //     'wpml:gimbalRollRotateEnable': 0,
      //     'wpml:payloadPositionIndex': 0,
      //     'wpml:gimbalRollRotateAngle': 0,
      //     'wpml:gimbalRotateMode': 'absoluteAngle',
      //     'wpml:gimbalPitchRotateAngle': 0,
      //     'wpml:gimbalPitchRotateEnable': 0,
      //     'wpml:gimbalYawRotateEnable': 1,
      //     'wpml:gimbalYawRotateAngle': -136.1
      //   }
      // },
      // actiontakePhoto: { // rotateYaw飞行器偏航
      //   actionActuatorFunc: '',
      //   actionId: '',
      //   actionActuatorFuncParam: {
      //     'wpml:payloadPositionIndex': '',
      //     'wpml:fileSuffix': '',
      //     'wpml:payloadLensIndex': '',
      //     'wpml:useGlobalPayloadLensIndex': 1
      //   }
      // },
      // actionstartRecord: { // rotateYaw飞行器偏航
      //   actionActuatorFunc: '',
      //   actionId: '',
      //   actionActuatorFuncParam: {
      //     'wpml:payloadPositionIndex': '',
      //     'wpml:fileSuffix': '',
      //     'wpml:payloadLensIndex': '',
      //     'wpml:useGlobalPayloadLensIndex': 1
      //   }
      // },
      // actionstopRecord: { // rotateYaw飞行器偏航
      //   actionActuatorFunc: '',
      //   actionId: '',
      //   actionActuatorFuncParam: {
      //     'wpml:payloadPositionIndex': '',
      //     'wpml:payloadLensIndex': '',
      //   }
      // },
      // actionfocus: { // rotateYaw飞行器偏航
      //   actionActuatorFunc: '',
      //   actionId: '',
      //   actionActuatorFuncParam: {
      //     'wpml:payloadPositionIndex': '',
      //     'wpml:isPointFocus': 1, // 是否点对焦
      //     'wpml:focusX': 0,
      //     'wpml:focusY': 0,
      //     'wpml:focusRegionWidth': 0,
      //     'wpml:focusRegionHeight': 0,
      //     'wpml:isInfiniteFocus': 0,
      //   }
      // },
      // actionzoom: { // rotateYaw飞行器偏航
      //   actionActuatorFunc: '',
      //   actionId: '',
      //   actionActuatorFuncParam: {
      //     'wpml:payloadPositionIndex': '',
      //     'wpml:actionzoom': 0,
      //   }
      // },
      // actioncustomDirName: { // rotateYaw飞行器偏航
      //   actionActuatorFunc: '',
      //   actionId: '',
      //   actionActuatorFuncParam: {
      //     'wpml:payloadPositionIndex': '',
      //     'wpml:directoryName': '',
      //   }
      // },

    })
    console.log(state.waylinePointMsg, '添加标点信息成功')
  },
  SET_WALINE_ALLMSG(state, msg) { // 编辑航线文件获取信息时录入数据
    state.waylinePointMsg.push({
      index: msg.index,
      Point: {
        longitude: msg.position.lng,
        latitude: msg.position.lat,
      },
      height: msg.height, // 航点高度（海拔高度？）
      ellipsoidHeight: msg.dixingHeight, // 航点椭球高度(即相对地面高度)
      waypointTurnMode: msg.waypointTurnMode, // 航点类型
      waypointHeadingMode: msg.waypointHeadingMode, // 飞行器偏航角模式
      mappingHeadingAngle: '', // 飞行器偏航角 0-360
      waypointSpeed: msg.waypointSpeed, // 航点飞行速度
      useGlobalHeight: false, // 是否使用全局高度
      useGlobalTurnParam: true, // 是否使用全局航点类型（全局航点转弯模式）
      useGlobalHeadingParam: true, // 是否使用全局偏航角模式参数
      waypointHeadingAngle: Number(msg.waypointHeadingAngle), // 飞行器偏航角度
      useGlobalSpeed: true, // 是否全局速度
      waypointHeadingPathMode: msg.waypointHeadingParam, // 飞行器偏航角转动方向
      action: msg.action,
    })
  },
  // DELETE_WAYLINE_MSG (state, id) { // 删除航点
  //   // console.log(state.waylinePointMsg, 'msg')
  //   // console.log(id, 'id')

  //   state.waylinePointMsg = state.waylinePointMsg.filter((item:any) => {
  //     return item.index !== id
  //   })
  // },
  DELETE_WAYLINE_MSG(state, id) { // 上面是以前的删除单个航点，现在是删除之后的
    const index = state.waylinePointMsg.filter((item: any) => {
      return item.index == id
    })[0].index // 找到id是数组中的第几个
    state.waylinePointMsg.splice(index)
  },
  SET_VIEWEROK(state, boll) {
    state.isViewOk = boll
  },
  SET_PROJECT(state, msg) {
    state.projectMsg = { ...msg }
  },

  SET_POINTMSG(state, msg) {
    state.waylineMSG = msg
  },
  ADD_POINTINDEX(state, bool?) {
    if (bool) {
      state.waylineIndex = 0
    } else {
      state.waylineIndex++
    }
  },
  CLEAR_ALLPOINT(state) {
    state.waylineIndex = 0
    state.waylineCoordinates = []
    state.waylineMarkers = []
    state.waylinePoints = []
    state.waylineMSG = {}
  },
  // PUSH_POINTS (state,) {},
  PUSH_POTIN(state, message: any) {
    (state.waylineMarkers as any).push({ marker: message[1], index: state.waylineIndex });
    (state.waylinePoints as any).push(
      {
        index: state.waylineIndex,
        Point: {
          longitude: message[0][0],
          latitude: message[0][1]// 纬度
        },
        height: 1, // 航点高度
        ellipsoidHeight: 0, // 航点椭球高度
        waypointTurnMode: 'toPointAndStopWithDiscontinuityCurvature', // 航点类型
        waypointHeadingMode: 'smoothTransition', // 飞行器偏航角模式
        mappingHeadingAngle: '', // 飞行器偏航角 0-360
        waypointSpeed: 1, // 航点飞行速度
        useGlobalHeight: false, // 是否使用全局高度
        useGlobalTurnParam: false, // 是否使用全局航点类型（全局航点转弯模式）
        useGlobalHeadingParam: false, // 是否使用全局偏航角模式参数
        waypointHeadingAngle: 0, // 飞行器偏航角度
        useGlobalSpeed: false, // 是否全局速度
        waypointHeadingPathMode: 'followBadArc', // 飞行器偏航角转动方向
        action: [],

        // actionhover: { // hover悬停SA
        //   actionActuatorFunc: '',
        //   actionId: '',
        //   actionActuatorFuncParam: {
        //     'wpml:hoverTime': ''
        //   }
        // },
        // actionrotateYaw: { // rotateYaw飞行器偏航
        //   actionActuatorFunc: '',
        //   actionId: '',
        //   actionActuatorFuncParam: {
        //     'wpml:aircraftHeading': '', // 飞行器目标偏航角 -180 180
        //     'wpml:aircraftPathMode': ''
        //   }
        // },
        // actiongimbalRotate: { // rotateYaw飞行器偏航
        //   actionActuatorFunc: '',
        //   actionId: '',
        //   actionActuatorFuncParam: {
        //     'wpml:gimbalRotateTime': 0,
        //     'wpml:gimbalHeadingYawBase': 'north',
        //     'wpml:gimbalRotateTimeEnable': 0,
        //     'wpml:gimbalRollRotateEnable': 0,
        //     'wpml:payloadPositionIndex': 0,
        //     'wpml:gimbalRollRotateAngle': 0,
        //     'wpml:gimbalRotateMode': 'absoluteAngle',
        //     'wpml:gimbalPitchRotateAngle': 0,
        //     'wpml:gimbalPitchRotateEnable': 0,
        //     'wpml:gimbalYawRotateEnable': 1,
        //     'wpml:gimbalYawRotateAngle': -136.1
        //   }
        // },
        // actiontakePhoto: { // rotateYaw飞行器偏航
        //   actionActuatorFunc: '',
        //   actionId: '',
        //   actionActuatorFuncParam: {
        //     'wpml:payloadPositionIndex': '',
        //     'wpml:fileSuffix': '',
        //     'wpml:payloadLensIndex': '',
        //     'wpml:useGlobalPayloadLensIndex': 1
        //   }
        // },
        // actionstartRecord: { // rotateYaw飞行器偏航
        //   actionActuatorFunc: '',
        //   actionId: '',
        //   actionActuatorFuncParam: {
        //     'wpml:payloadPositionIndex': '',
        //     'wpml:fileSuffix': '',
        //     'wpml:payloadLensIndex': '',
        //     'wpml:useGlobalPayloadLensIndex': 1
        //   }
        // },
        // actionstopRecord: { // rotateYaw飞行器偏航
        //   actionActuatorFunc: '',
        //   actionId: '',
        //   actionActuatorFuncParam: {
        //     'wpml:payloadPositionIndex': '',
        //     'wpml:payloadLensIndex': '',
        //   }
        // },
        // actionfocus: { // rotateYaw飞行器偏航
        //   actionActuatorFunc: '',
        //   actionId: '',
        //   actionActuatorFuncParam: {
        //     'wpml:payloadPositionIndex': '',
        //     'wpml:isPointFocus': 1, // 是否点对焦
        //     'wpml:focusX': 0,
        //     'wpml:focusY': 0,
        //     'wpml:focusRegionWidth': 0,
        //     'wpml:focusRegionHeight': 0,
        //     'wpml:isInfiniteFocus': 0,
        //   }
        // },
        // actionzoom: { // rotateYaw飞行器偏航
        //   actionActuatorFunc: '',
        //   actionId: '',
        //   actionActuatorFuncParam: {
        //     'wpml:payloadPositionIndex': '',
        //     'wpml:actionzoom': 0,
        //   }
        // },
        // actioncustomDirName: { // rotateYaw飞行器偏航
        //   actionActuatorFunc: '',
        //   actionId: '',
        //   actionActuatorFuncParam: {
        //     'wpml:payloadPositionIndex': '',
        //     'wpml:directoryName': '',
        //   }
        // },

      }
    )
    state.waylineCoordinates.push(message[0])
    console.log('push', state.waylinePoints)
    console.log('push', state.waylineMarkers)
  },
  DELETE_POINT(state, index) {
    const map = getRoot().$map
    const temp: any = state.waylineMarkers.find(item => item.index === index)
    map.remove(temp.marker)
    state.waylineMarkers.splice(state.waylineMarkers.findIndex(item => item.index === index), 1)
    state.waylinePoints.splice(state.waylinePoints.findIndex(item => item.index === index), 1)
    console.log('删除后', state.waylinePoints)
  },
  SET_POINT(state, form) {
    console.log(form, 'store中')
    for (let i = 0; i < state.waylinePoints.length; i++) {
      if (JSON.stringify(state.waylinePoints[i].Point) === JSON.stringify(form.Point)) {
        state.waylinePoints[i] = { ...form, index: state.waylinePoints[i].index }
      }
    }
    console.log(state.waylinePoints, '赋值后')
  },
  CLEAR_POINT(state) {
    state.waylineIndex = 0
    state.waylineMarkers = []
    state.waylinePoints = []
  },
  SET_WAYLINE_VISIBLE(state, name) { // 调整绘图区域
    state.createWaylineVisible = name
  },
  SET_LAYER_INFO(state, info) {
    state.Layers = info
  },
  SET_DEVICE_INFO(state, info) {
    console.log(info, '设置飞机信息')

    state.deviceState.deviceInfo[info.sn] = info.host
    state.deviceState.currentSn = info.sn
    state.deviceState.currentType = EDeviceTypeName.Aircraft
    // console.log(state.deviceState, '???')
  },
  SET_GATEWAY_INFO(state, info) {
    // console.log('设置网关信息')

    state.deviceState.gatewayInfo[info.sn] = info.host
    state.deviceState.currentSn = info.sn
    state.deviceState.currentType = EDeviceTypeName.Gateway
  },
  // 设置当前在线设备信息
  SET_DOCK_INFO(state, info) {
    // 如果host为空对象，返回
    if (Object.keys(info.host).length === 0) {
      return
    }
    // 如果机场信息中不存在info中的sn属性，添加
    if (!state.deviceState.dockInfo[info.sn]) {
      state.deviceState.dockInfo[info.sn] = {} as DockOsd
    }
    // 设置当前设备的sn
    state.deviceState.currentSn = info.sn
    // 当前设备为机场
    state.deviceState.currentType = EDeviceTypeName.Dock

    // 找到属于info的dock信息
    const dock = state.deviceState.dockInfo[info.sn]

    if (info.host.mode_code !== undefined) {
      dock.basic_osd = info.host
      return
    }
    if (info.host.sdr) {
      dock.link_osd = info.host
      return
    }
    if (info.host.job_number !== undefined) {
      dock.work_osd = info.host
    }
    console.log('设置机场信息', info)
  },
  SET_DRAW_VISIBLE_INFO(state, bool) {
    state.drawVisible = bool
  },
  // 创建标注
  SET_MAP_ELEMENT_CREATE(state, info) {
    console.log(info, 'info')

    state.wsEvent.mapElementCreat = info
  },
  SET_MAP_ELEMENT_UPDATE(state, info) {
    state.wsEvent.mapElementUpdate = info
  },
  SET_MAP_ELEMENT_DELETE(state, info) {
    state.wsEvent.mapElementDelete = info
  },
  SET_DEVICE_ONLINE(state, info) {
    state.deviceStatusEvent.deviceOnline = info
  },
  SET_DEVICE_OFFLINE(state, info) {
    state.deviceStatusEvent.deviceOffline = info
    delete state.deviceState.gatewayInfo[info.sn]
    delete state.deviceState.deviceInfo[info.sn]
    delete state.deviceState.dockInfo[info.sn]
    delete state.hmsInfo[info.sn]
    // delete state.markerInfo.coverMap[info.sn]
    // delete state.markerInfo.pathMap[info.sn]
  },
  // 设置显示的实时信息
  SET_OSD_VISIBLE_INFO(state, info) {
    state.osdVisible = info
  },

  SET_SELECT_DOCK_INFO(state, info) {
    console.log('这里设置的吗')

    state.dockInfo = info
  },
  SET_DEVICE_HMS_INFO(state, info) {
    const hmsList: Array<DeviceHms> = state.hmsInfo[info.sn]
    state.hmsInfo[info.sn] = info.host.concat(hmsList ?? [])
  },
  SET_DEVICES_CMD_EXECUTE_INFO(state, info) { // 保存设备指令ws消息推送
    if (!info.sn) {
      return
    }
    if (state.devicesCmdExecuteInfo[info.sn]) {
      const index = state.devicesCmdExecuteInfo[info.sn].findIndex(cmdExecuteInfo => cmdExecuteInfo.biz_code === info.biz_code)
      if (index >= 0) {
        // 丢弃前面的消息
        if (state.devicesCmdExecuteInfo[info.sn][index].timestamp > info.timestamp) {
          return
        }
        state.devicesCmdExecuteInfo[info.sn][index] = info
      } else {
        state.devicesCmdExecuteInfo[info.sn].push(info)
      }
    } else {
      state.devicesCmdExecuteInfo[info.sn] = [info]
    }
  },
  SET_MQTT_STATE(state, mqttState) {
    state.mqttState = mqttState
  },
  SET_CLIENT_ID(state, clientId) {
    state.clientId = clientId
  },
}

const actions: ActionTree<RootStateType, RootStateType> = {
  selectFolder({ state }, id) {
    state.folderId = id
    console.log(id, '改变文件夹')
  },
  async getAllElement({ commit }) {
    const result = await getLayers({
      groupId: '',
      isDistributed: true
    })
    commit('SET_LAYER_INFO', result.data?.list)
    console.log(result)
  },
  updateElement({ state }, content: { type: 'is_check' | 'is_select', id: string, bool: boolean }) {
    // const key = content.id.replaceAll('resource__', '')//原来的，用的树所以需要去掉resource__
    const key = content.id
    const type = content.type
    const layers = state.Layers
    layers.forEach((item) => {
      item.type = 1
    })
    const layer = layers.find(item => item.id === key)
    if (layer) {
      layer[type] = content.bool
      layer.type = 2
    }
  },
  setLayerInfo({ state }, layers) {
    // const layers = state.Layers
    const obj: {
      [key: string]: string
    } = {}
    layers.forEach(layer => {
      if (layer.type === LayerType.Default) {
        obj.default = layer.id
      } else {
        if (layer.type === LayerType.Share) {
          obj.share = layer.id
        }
      }
    })
    state.layerBaseInfo = obj
    console.log('state.layerBaseInfo', state.layerBaseInfo)
  },
  getLayerInfo({ state }, id: string) {
    return state.layerBaseInfo[id]
  }
}

const modules = {
  device: deviceModule,
  authority: authorityModule
}

const plugins = [
  createPersistedstate({
    key: 'quanxian',
    paths: ['authority']
  }),

]

const storeOptions: StoreOptions<RootStateType> = {
  state: initStateFunc,
  getters,
  mutations,
  actions,
  modules,
  plugins
}

const rootStore = createStore(storeOptions)

export default rootStore

export const storeKey: InjectionKey<Store<RootStateType>> = Symbol('')

type AllStateStoreTypes = RootStateType & {
  // moduleName: moduleType
}

export function useMyStore<T = AllStateStoreTypes>() {
  return useStore<T>(storeKey)
}
